import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Slider from "react-slick"



const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000
}

const Caroussel = ({ data }) => {
  const query = useStaticQuery(graphql`
  query HeaderQuery2 {
    image1:file(absolutePath: {regex: "/ambiance_fangqi_paysage_desktop.jpg/"}) {
      childImageSharp {
        fluid (maxWidth: 1600, quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2:file(absolutePath: {regex: "/ambiance_cuitong_sommeil_desktop.jpg/"}) {
      childImageSharp {
        fluid (maxWidth: 1600, quality: 90){
          ...GatsbyImageSharpFluid
        }
      }
    }
    image1_smart:file(absolutePath: {regex: "/ambiance_fangqi_paysage_smartphone2.jpg/"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2_smart:file(absolutePath: {regex: "/ambiance_cuitong_sommeil_smartphone2.jpg/"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  const desktop = useMediaQuery('(min-width:600px)');
  if (desktop) {
    return (
      <Slider {...settings} >
        <Img fluid={query.image1.childImageSharp.fluid} />
        <Img fluid={query.image2.childImageSharp.fluid} />
      </Slider>

    )
  } else return (
    <Slider {...settings} >
      <Img fluid={query.image1_smart.childImageSharp.fluid} />
      <Img fluid={query.image2_smart.childImageSharp.fluid} />
    </Slider>



  )

}




export default Caroussel;
