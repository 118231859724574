import * as React from 'react';
import { Bio, Gallerie } from './components';

import { makeStyles, Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet"
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import Img from "gatsby-image" // to take image data and render it

const useStyles = makeStyles(theme => ({
  section: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: "50px",
      paddingRight: "50px",
      paddingLeft: "50px",
    },

  },
}));


const Peintures = () => {


  const classes = useStyles();


  return (
    <div className={classes.section}>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Oeuvres</title>
        <link rel="canonical" href={"https://elseink.com/oeuvres"} />

      </Helmet>
      <Gallerie /></div >

  );
}


export default Peintures;
