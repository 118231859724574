import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { GetStarted, Features, Reviews, Gallerie, Services, Caroussel } from './components';
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import { Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  slider: {
    padding: 0,
    //height: "400px"
  },
  concept: {
    paddingTop: 20,
    paddingBottom: 40,

  },
  gallery: {
    padding: 0,
    paddingLeft: 40,
  },
  cadre_global: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  cadre_gallerie: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  cadre_gallerie_smart: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  ligne_button: {
    height: "100px",
    position: "relative",
    paddingTop: "10px",

  },
  bouton: {
    margin: 0,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    //transform: "translateY(-50%)",

    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "Inconsolata,sans-serif",
    fontWeight: 400,
    letterSpacing: ".3em",
    lineHeight: "2em",
    //margin: 50,
    outline: 0,
    //position: "relative",
    textTransform: "uppercase",
    verticalAlign: "middle",
    width: "auto",
    fontSize: "14px",
    padding: "10px 83px",
    color: "#595959",
    backgroundColor: "transparent",
    border: "1px solid #595959",
    borderRadius: "0px",
  },
}));

const IndexView = ({ themeMode }) => {
  const classes = useStyles();
  const desktop = useMediaQuery('(min-width:600px)');
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Elseink</title>
        <link rel="canonical" href={"https://elseink.com"} />
        <link href={withPrefix('slick.min.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('slick-theme.min.css')} rel="stylesheet" type="text/css" />

      </Helmet>
      <div className={classes.cadre_global}><Caroussel /></div>


      <div className={classes.concept}><Services type={"1"} /></div>
      <div className={desktop ? classes.cadre_gallerie : classes.cadre_gallerie_smart}><Gallerie ligne={"1"} /></div>
      <div className={desktop ? classes.cadre_gallerie : classes.cadre_gallerie_smart}><Gallerie ligne={"2"} /></div>
      <div className={classes.ligne_button}>
        <Link to={"/oeuvres"}>
          <Button
            fullWidth
            size="large"
            className={classes.bouton}

          >Découvrir les oeuvres</Button></Link>
      </div>
      <div className={classes.concept}>
        <Services type={"2"} />

      </div>

    </div >
  );
};



export default IndexView;
