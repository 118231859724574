import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby"
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const useStyles = makeStyles(theme => ({
  folioItem: {
    margin: "0px",
    //position: 'relative',
    //overflow: 'hidden',
    //margin: theme.spacing(1, 0),
    //boxShadow: `0 1.5rem 4rem rgba(22,28,45,.05)`,
    //borderRadius: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      //margin: theme.spacing(2),
    },
    '&:last-child': {
      [theme.breakpoints.up('md')]: {
        //marginBottom: 0,
      },
    },
    '&:hover': {
      '& .folio__image': {
        transform: 'scale(1.02)',
      },
      '& .folio__info-wrapper': {
        transform: 'translateY(0)',
      },
    },
  },
  image: {
    transitionDuration: '.7s',
    transform: 'scale(1.0)',
    objectFit: 'cover',
    maxHeight: "250px",
    width: "auto",
    //height: "auto",
    //maxWidth: "200px",
  },
  folioTitle: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    color: "#595959",
    textAlign: 'center',
  },
  folioSubtitle: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#595959",
    textAlign: 'center',
    margin: theme.spacing(1, 0),
  },
  grid: {
    display: 'flex',

    justifyContent: "space-around",
    //width:300,
    flexDirection: 'row',
    //flexWrap: "wrap"
    /*[theme.breakpoints.up('sm')]: {
      height: 500,
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      maxHeight: '100%',
    },*/
  },
  grid_smart: {
    //display: 'flex',

    justifyContent: "space-around",
    //width:300,
    flexDirection: 'row',
    //flexWrap: "wrap"
    /*[theme.breakpoints.up('sm')]: {
      height: 500,
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      maxHeight: '100%',
    },*/
  },
}));

class PeintureData {
  constructor(titre, artiste, slug, fluid, home) {
    this.titre = titre;
    this.artiste = artiste;
    this.slug = slug;
    this.fluid = fluid;
    this.home = home;
  }
}

const Gallerie = props => {
  const { className, ligne, ...rest } = props;
  const classes = useStyles();
  const desktop = useMediaQuery('(min-width:600px)');
  const query2 = useStaticQuery(graphql`
  query HeaderQuery3 {
    allFile(filter: {absolutePath: {regex: "/cover/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              presentationHeight
            }
          }
          absolutePath
        }
      }
    }
    
    ligne1:allMarkdownRemark(filter: {frontmatter: {type: {regex: "/peinture/"}, home: {regex: "/1_./"}}}) {
          edges {
            node {
              fileAbsolutePath
              frontmatter {
                home
                titre
                artiste
                slug
              }
            }
          }
        }
        ligne2:allMarkdownRemark(filter: {frontmatter: {type: {regex: "/peinture/"}, home: {regex: "/2_./"}}}) {
          edges {
            node {
              fileAbsolutePath
              frontmatter {
                home
                titre
                artiste
                slug
              }
            }
          }
        }
  }
`)
  var peintures = new Object();
  var mdSelectionnes;
  if (ligne == 1) {
    mdSelectionnes = query2.ligne1.edges;
  } else {
    mdSelectionnes = query2.ligne2.edges;
  }
  for (const node of mdSelectionnes) {
    let mdPath = node.node.fileAbsolutePath;
    let path = mdPath.substring(0, mdPath.lastIndexOf('/'));
    let home = node.node.frontmatter.home;
    let titre = node.node.frontmatter.titre;
    let artiste = node.node.frontmatter.artiste;
    let slug = node.node.frontmatter.slug;
    peintures[path] = new PeintureData(titre, artiste, slug, "", home);
  }


  for (const node of query2.allFile.edges) {
    let imagePath = node.node.absolutePath;
    let path = imagePath.substring(0, imagePath.lastIndexOf('/'));
    if (peintures[path]) {
      peintures[path] = new PeintureData(peintures[path].titre, peintures[path].artiste, peintures[path].slug, node.node.childImageSharp.fluid, peintures[path].home);
    }
  }

  let wrapper = [[], [], []];
  let hauteurs = [0, 0, 0];
  let row = [];
  let sommeRatios = 0;

  for (var path in peintures) {
    let id_colonne = 0;
    if (hauteurs[0] < hauteurs[1] && hauteurs[0] < hauteurs[2]) {
      id_colonne = 0;
    } else if (hauteurs[1] <= hauteurs[0] && hauteurs[1] <= hauteurs[2]) {
      id_colonne = 1;
    } else if (hauteurs[2] <= hauteurs[1] && hauteurs[2] <= hauteurs[0]) {
      id_colonne = 2;
    }

    var peinture = peintures[path];
    hauteurs[id_colonne] = hauteurs[id_colonne] + peinture.fluid.presentationHeight;
    wrapper[id_colonne].push(peinture);
    row.push(peinture);
    sommeRatios = sommeRatios + peinture.fluid.aspectRatio;
  }

  const spacing = 17;

  var widthRestant = 1000;
  var widthRestantSmartphone = 600;
  if (isBrowser) {
    const [width, setWidth] = React.useState(window.innerWidth);
    widthRestant = width - 2 * mdSelectionnes.length * spacing;
    widthRestantSmartphone = width;
    const breakpoint = 620;

    React.useEffect(() => {
      /* Inside of a "useEffect" hook add an event listener that updates
         the "width" state variable when the window size changes */
      window.addEventListener("resize", () => setWidth(window.innerWidth));

      /* passing an empty array as the dependencies of the effect will cause this
         effect to only run when the component mounts, and not each time it updates.
         We only want the listener to be added once */
    }, []);
  }

  function compare(a, b) {
    if (a.home < b.home) {
      return -1;
    }
    if (a.home > b.home) {
      return 1;
    }
    return 0;
  }

  row.sort(compare);


  return (
    <div  >
      <div className={desktop ? classes.grid : classes.grid_smart}>
        {row.map((peinture, index) => (

          <div key={index} className={classes.itemContainer}>
            <div
              className={classes.folioItem}
              key={index}
            //data-aos="fade-up"
            //style={{ height: item.h }}
            >
              <Link to={peinture.slug}>
                <Image
                  src={peinture.fluid.src}
                  //className={clsx('folio__image', classes.image)}
                  style={desktop ? {
                    transitionDuration: '.7s',
                    transform: 'scale(1.0)',
                    objectFit: 'cover',
                    maxHeight: widthRestant / sommeRatios,
                    width: "auto",
                    padding: spacing + "px",
                  } : {
                    transitionDuration: '.7s',
                    transform: 'scale(1.0)',
                    objectFit: 'cover',
                    //maxHeight: widthRestant / sommeRatios,
                    //width: "auto",
                    //maxHeight: "auto",
                    //width: "100px",//widthRestantSmartphone,
                    //padding: spacing + "px",
                  }}
                  lazy={false}
                />
              </Link>
              <div
                className={clsx(
                  'folio__info-wrapper',
                  classes.folioInfoWrapper,
                )}
              >


                <Typography
                  variant="body1"
                  className={classes.folioTitle}
                  color="textSecondary"
                >
                  {peinture.titre}
                </Typography>
                <Link to={peinture.slug.split("/")[1]}>
                  <Typography
                    variant="body1"
                    className={classes.folioSubtitle}
                    color="textSecondary"
                  >

                    {peinture.artiste}

                  </Typography>
                </Link>

              </div>

            </div>


          </div>

        ))}
      </div>
    </div>
  );

  return (
    <div  >
      <div className={classes.grid}>
        {row.map((peinture, index) => (

          <div key={index} className={classes.itemContainer}>
            <div
              className={classes.folioItem}
              key={index}
            //data-aos="fade-up"
            //style={{ height: item.h }}
            >
              <Link to={peinture.slug}>
                <Image
                  src={peinture.fluid.src}
                  //className={clsx('folio__image', classes.image)}
                  style={{
                    transitionDuration: '.7s',
                    transform: 'scale(1.0)',
                    objectFit: 'cover',
                    maxHeight: widthRestant / sommeRatios,
                    width: "auto",
                    padding: spacing + "px",
                  }}
                  lazy={false}
                />
              </Link>
              <div
                className={clsx(
                  'folio__info-wrapper',
                  classes.folioInfoWrapper,
                )}
              >


                <Typography
                  variant="body1"
                  className={classes.folioTitle}
                  color="textSecondary"
                >
                  {peinture.titre}
                </Typography>
                <Link to="/cuitong">
                  <Typography
                    variant="body1"
                    className={classes.folioSubtitle}
                    color="textSecondary"
                  >

                    {peinture.artiste}

                  </Typography>
                </Link>

              </div>

            </div>


          </div>

        ))}
      </div>
    </div>
  );
};

export default Gallerie;
